import React from 'react'
import { Container } from 'react-bootstrap'
import '../../styles/laboratory.css'
// import physicsLab from '../../images/PhysicsLab.jpg'
// import physicsLab1 from '../../images/physicsLab1.jpg'
// import chemistryLab from '../../images/chemistryLab.jpg'
// import chemistryLab1 from '../../images/chemistryLab1.jpg'
// import biologyLab from '../../images/biologyLab.jpg'
// import biologyLab1 from '../../images/biologyLab1.png'
// import csLab from '../../images/csLab.png'
import Head from '../Head';
import { useEffect } from 'react'
import AOS from 'aos';

function Laboratory() {

    useEffect(() => {
        AOS.init({
          duration : 1500
        });
        AOS.refresh();
    
    }, []);

  return (
        <div className='overflow-hidden '>
        <Head title="Laboratory"/>

          <Container className="px-5" >
            <header className="sectionHeader halfBorder mb-5" as="h1">Laboratory</header>
            
            <div className='labSection'>
                <ul data-aos="fade-right">
                    <li className='star'><a href='#physics'  >Physics</a></li>
                    <li className='star'><a href='#chemistry' >Chemistry</a></li>
                    <li className='star'><a href='#biology' >Biology</a></li>
                    <li className='star'><a href='#computerScience' >Computer Science</a></li>
                </ul>
            </div>

            <section id='physics'>
                <header className="sectionHeader halfBorder mb-5" as="h1">Physics</header>

                <div className='mb-5'>
                    <p className="sectionContent">
                        The Department is well equipped with facilities to train the students for all the practical activities prescribed by P.U.Board. <br />
                        The department has sophisticated equipment's to fulfill the needs of the PUC Studies.                
                    </p>
                </div>

                <div className="gallery d-flex flex-wrap"> 
                    <figure data-aos="fade-up-left">
                        {/* <img fluid  src={physicsLab} alt="physics lab" /> */}
                    </figure>   
                    <figure data-aos="fade-up-right">
                        {/* <img fluid  src={physicsLab1} alt="physics lab" /> */}
                    </figure>   
                </div>
            </section>

            <section id='chemistry'>
                <header className="sectionHeader halfBorder mb-5" as="h1">Chemistry</header>

                <div className='mb-5'>
                    <p className="sectionContent">
                        The Department provides the necessary infrastructure for the good understanding of chemistry. In addition to the usual apparatus <br />
                        provided in the laboratory, the department also has advanced equipment's.               
                    </p>
                </div>

                <div className="gallery d-flex flex-wrap"> 
                    <figure data-aos="fade-up-left">
                        {/* <img fluid  src={chemistryLab} alt="chemistry lab" /> */}
                    </figure>   
                    <figure data-aos="fade-up-right">
                        {/* <img fluid  src={chemistryLab1} alt="chemistry lab" /> */}
                    </figure>    
                </div>
            </section>

            <section id='biology'>
                <header className="sectionHeader halfBorder mb-5 " as="h1">Biology</header>

                <div className='mb-5'>
                    <p className="sectionContent">
                        The Department of biology has adequate specimens of Zoology and botanical equipment's of PUC the department offer various <br />
                         aids to demonstrate the subject.             
                    </p>
                </div>

                <div className="gallery d-flex flex-wrap"> 
                    <figure data-aos="fade-up-left">
                        {/* <img fluid  src={biologyLab} alt="biology lab" /> */}
                    </figure>   
                    <figure data-aos="fade-up-right">
                        {/* <img fluid  src={biologyLab1} alt="biology lab" /> */}
                    </figure>   
                </div>
            </section>

            <section id='computerScience'>
                <header className="sectionHeader halfBorder mb-5" as="h1">Computer Science</header>

                <div className='mb-5'>
                    <p className="sectionContent">
                        The Department is well equipped with facilities to train the students for all the practical activities prescribed by the P.U.board.               
                    </p>
                </div>

                <div className="gallery d-flex flex-wrap"> 
                    <figure data-aos="fade-up-left">
                        {/* <img fluid  src={csLab} alt="computerScience lab" /> */}
                    </figure>   
                    <figure data-aos="fade-up-right">
                        {/* <img fluid  src={csLab} alt="computerScience lab" /> */}
                    </figure>    
                </div>
            </section>

          </Container>
        </div>
  )
}

export default Laboratory