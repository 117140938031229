import React from 'react'
import { Container } from 'react-bootstrap'
import Layout from "../components/Layout"
import LaboratoryComponent from "../components/campus/Laboratory"

function Laboratory() {
  return (
    <div className='overflow-hidden '>
        <Layout>

            <Container className="px-5">
                <div style={{marginTop:'6rem'}}>
                    <LaboratoryComponent />
                </div>
            </Container>
        </Layout>
    </div>
  )
}

export default Laboratory